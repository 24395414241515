<template>
  <div>
    
  <b-row>
    <b-col>
      <b-card>
        <b-row v-if="state === 'show'">
          <b-col cols="4">
            <h4>Completed Checklist</h4>
            <data-label :data="checklistCategories.user" :labelName="'Completed By:'" :labelRight="false" />
            <data-label :data="checklistCategories.dateCompleted | dateTimeFilter" :labelName="'Date Completed:'" :labelRight="false"  />
            <b-button variant="primary" block @click="dodownloadUserChecklistResponse()">Download Answers</b-button>
          </b-col>
          <b-col cols="8">
        <b-row v-for="(item, index) in checklistCategories.categories" :key="index">
            <b-col cols="12" class="text-center">
                <h4 class="text-left">{{item.description}}</h4>
                <img v-if="item.imageFile !== null" :src="returnFileObject(item.imageFile)" alt="" 
                class="checklist-images" @click="openModalImage(item.imageFile)">
            </b-col>
            <b-col cols="12">

                <b-row class="m-0 p-0" v-for="(q, qIndex) in item.questions" :key="qIndex">
                    <img v-if="q.imageFile !== null" :src="returnFileObject(q.imageFile)" alt="" 
                    class="checklist-images" @click="openModalImage(q.imageFile)">
                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Text'">
                        <label>{{q.question}}</label>
                        <b-form-input v-model="q.answer" :disabled="true"></b-form-input>
                    </b-col>

                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Number'">
                        <label>{{q.question}}</label>
                        <b-form-input v-model="q.answer" type="number" :disabled="true"></b-form-input>
                    </b-col>

                    
                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'MultiLevel'">
                        <label>{{q.question}}</label>
                        <b-form-input v-model="q.answer" :disabled="true"></b-form-input>
                    </b-col>

                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'YesNo'">
                        <label>{{q.question}}</label>
                        
                        <b-form-radio-group v-model="q.answer" :disabled="true">
                            <b-form-radio value="Yes">Yes</b-form-radio>
                            <b-form-radio value="No">No</b-form-radio>
                        </b-form-radio-group>
                    </b-col>
                    
                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'LongText'">
                        <label>{{q.question}}</label>
                        <b-form-textarea :disabled="true"
                            class="pt-2"
                            rows="2"
                            size="sm"
                            v-model="q.answer"
                        ></b-form-textarea>
                    </b-col>

                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'DateTime'">
                        <label>{{q.question}}</label>
                        <b-form-datepicker v-model="q.answer" :disabled="true"></b-form-datepicker>
                    </b-col>
                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Date'">
                        <label>{{q.question}}</label>
                        <b-form-datepicker v-model="q.answer" :disabled="true"></b-form-datepicker>
                    </b-col>
                    
                        <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Time'">
                            <label>{{q.question}}</label>
                        <b-form-input v-model="q.answer" :disabled="true"></b-form-input>
                        </b-col>

                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'MultiSelect'">
                        <label>{{q.question}}</label>
                        <b-form-input v-model="q.answer" :disabled="true"></b-form-input>
                    </b-col>
                    
                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'RadioButton'">
                        <label>{{q.question}}</label>
                        <b-form-input v-model="q.answer" :disabled="true"></b-form-input>
                    </b-col>

                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'SingleSelect'">
                        <label>{{q.question}}</label>
                        <b-form-input v-model="q.answer" :disabled="true"></b-form-input>
                    </b-col>

                    <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Image'">
                        <label>{{q.question}}</label>
                        <b-button block class="mt-2" @click="openImageModal(q)">View Images</b-button>
                        <p class="text-center">({{q.answerImages.length}}) Files Uploaded</p>
                    </b-col>

                    <b-col cols="12" class="m-0 p-1 background-offset" v-if="q.answerNotes.length > 0">
                        <label class="bold">Notes</label>
                        <b-form-textarea
                            class="pt-2"
                            rows="2"
                            size="sm"
                            v-model="q.answerNotes[0]"
                            disabled
                        ></b-form-textarea>
                        <b-button block class="mt-2" @click="openImageModal(q)">View Images</b-button>
                        <p class="text-center">({{q.answerImages.length}}) Files Uploaded</p>
                    </b-col>

                </b-row>

            </b-col>
        </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
  
        <b-modal id="show-image-modal" hide-footer size="xl" no-header @close="closeModal()">
            <b-row v-if="showIndex < 0">
                <b-col cols="3" v-for="(item, index) in imageQuestion.answerImages" :key="index" class="text-center">
                    <img v-if="item.base64Data !== null" :src="returnFileObject(item)" alt="" class="fix-height-images" @click="changeShowIndex(index)">
                    <b-spinner v-if="item.base64Data === null"></b-spinner>
                </b-col>
            </b-row>
            <b-row v-if="showIndex > -1">
                <b-col cols="12" class="text-center">
                    <img v-if="imageQuestion.answerImages[showIndex].base64Data !== null" :src="returnFileObject(imageQuestion.answerImages[showIndex])" 
                        alt="" class="modal-images" @click="changeShowIndex(-1)">
                    <b-spinner v-if="imageQuestion.answerImages[showIndex].base64Data === null"></b-spinner>
                </b-col>
            </b-row>
        </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
// import completedChecklistAnswers from './completedChecklistAnswers.vue'
export default {
  //components: { completedChecklistAnswers },
  data: () => ({
    state: 'loading',
    checklistCategories: null,
    checklistScreenType: null,
    showIndex: -1,
    imageQuestion: {
      answerImages: []
    },
  }),

  created() {
    this.setBreadcrumb([
      {
          text: 'Completed Checklist'
      },
      {
          text: 'View',
          active: true
      },
    ])

    if (this.completedChecklist === null) {
      this.$router.push({path: '/checklist/completed'})
    }
    else {

      if (this.completedChecklist?.entityCheckListId) {
        this.getUserChecklistResponse()
        .then((res) => {
          console.log('res.data.result',res.data.result)

          this.checklistCategories = res.data.result
          this.state = 'show'
        })  
      } else {
        this.getChecklistResponse()
        .then((res) => {
          console.log('res.data',res.data)
          this.checklistCategories = res.data
          this.state = 'show'
          console.log(this.checklistCategories)

        })
      }

      
    }
  },

  methods: {
    ...mapActions(['getUserChecklistResponse', 'getChecklistResponse', 'downloadDocumentForJobCard', 'downloadUserChecklistResponse']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    
    dodownloadUserChecklistResponse(){
      this.state = "downloading"
      this.downloadUserChecklistResponse()
      .then((response) => {
        //console.log(response.data.result)
                const linkSource = `data:application/pdf;base64,${response.data.result.base64Data}`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = "Completed Checklist - " + this.completedChecklist.completedByUser;
                downloadLink.click();
        this.state = 'show'
      })
       .catch(() => {
              this.state = 'show'
            })
    },
  
    changeShowIndex(newIndex){
      this.showIndex = newIndex
    },
    returnFileObject(item){
      let base64 = 'data:' + item.contentType +';base64,'+ item.base64Data
      return base64
    },
    openImageModal(q){
      this.imageQuestion = q
      this.$root.$emit('bv::show::modal', 'show-image-modal', '#btnShow')
      this.showIndex = -1

      for (let i = 0; i < q.answerImages.length; i++) {
        const element = q.answerImages[i];
        this.$store.commit('setDownloadDocumentForJobCardRequest', element.documentId)
        this.downloadDocumentForJobCard()
        .then((res) => {
          element.base64Data = res.data.base64Data
          element.contentType = res.data.contentType
        })
      }

      
    },
    closeModal(){
      this.$root.$emit('bv::hide::modal', 'show-image-modal', '#btnShow')
    },
  }
  ,
    computed: {
      ...mapState(['completedChecklist'])
    },
  
}
</script>
